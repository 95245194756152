<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <!-- :style="{
          background: `url(${$store.state.appConfig.coverImage}) no-repeat top`,
          backgroundSize: 'cover',
        }" -->
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="$store.state.appConfig.coverImage" alt="image" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Welcome
            {{
              hostname == "connect.hpgs.pk"
                ? "to HPGS Connect"
                : hostname.includes("szablc.edu.pk")
                ? ""
                : "to mySkool"
            }}! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please enter your username and passowrd to sign-in your account
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Username" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="ulogin.username"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="Enter your username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="ulogin.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-link
                  hidden
                  :to="{ name: 'auth-forgot-password-v2' }"
                  class="float-right"
                >
                  <small>Forgot Password?</small>
                </b-link>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="loading"
                @click="mylogin()"
              >
                <span v-if="loading == false">Sign in</span>
                <div v-if="loading == true">
                  <b-spinner small variant="light" label="Spinning"></b-spinner>
                </div>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions } from "vuex";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    // BAlert,
    //VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "admin",
      userEmail: "admin@demo.com",
      sideImg: require("@/assets/images/pages/Logo-01.png"),
      ulogin: {
        username: "",
        password: "",
      },
      // validation rules
      required,
      email,
      loading: false,
      rights: {},
      hpgsCover: require("@/assets/images/logo/hpgs-logo.png"),
      hpgsSmall: require("@/assets/images/logo/hpgs-small.png"),
      szablcCover: require("@/assets/images/logo/szablc-cover.png"),
      szablcSmall: require("@/assets/images/logo/szablc-small.png"),
    };
  },
  computed: {
    hostname() {
      return window.location.hostname;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/Logo-01.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    ...mapActions({
      getString: "getString",
    }),
    mylogin() {
      //return console.log("Heloo");
      if (this.ulogin.username !== "" && this.ulogin.password !== "") {
        this.loading = true;
        var axios = require("axios");
        var data = this.ulogin;
        var mydata = {
          username: this.ulogin.email,
          password: this.ulogin.password,
        };
        var config = {
          method: "post",
          url: "https://connect.myskool.app/api/Tokens/UserLogin",
          data: data,
        };
        axios(config)
          .then(async (response) => {
            // console.log(response.data);
            this.username = response.data.data.username;
            this.role = response.data.data.role;
            if (
              response.data.data.role !== "parent" &&
              response.data.data.role !== "student" &&
              response.data.data.role !== "teacher"
            ) {
              //   if (response.data.data.status == 'active') {
              // console.log("MtData", response.data);
              this.$store.state.userData.token = response.data.token;
              this.$store.state.userData.cId = response.data.data.campusID;
              this.$store.state.userData.roles = response.data.data.role;
              this.$store.state.userData.username = response.data.data.username;
              this.$store.state.userData.db = response.data.data.db;
              this.$store.state.userData.userID = response.data.data.localID;
              this.$store.state.userData.password = this.ulogin.password;
              this.$store.state.userData.encPassword =
                response.data.data.password;
              // this.$store.state.userData.userID = response.data.data.id;
              //console.log( this.$store.state.userData.token);
              // console.log("))))))))))", this.$store.state.userData);
              this.$store.commit("setDomain", "https://api.myskool.app/api/");
              // this.$store.commit(
              //   "setDomain",
              //   "https://staging.myskool.app/api/"
              // );
              this.$store.commit(
                "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
                true
              );

              var obj = {
                url:
                  this.$store.state.domain +
                  "Campuses/GetClientID?db=" +
                  this.$store.state.userData.db,
                token: this.$store.state.userData.token,
              };
              let res = await this.getString(obj);
              this.$store.commit("setReport", res);
              // console.log(this.$store.state.report);

              var config = {
                url:
                  this.$store.state.domain +
                  "Users/GetSelected?id=" +
                  this.$store.state.userData.userID +
                  "&db=" +
                  this.$store.state.userData.db,
                headers: {
                  Authorization: "bearer " + this.$store.state.userData.token,
                },
              };
              axios(config)
                .then((res) => {
                  this.$store.commit("setLoggedUser", res.data.data);

                  let mm = JSON.parse(res.data.data.forms);
                  let obj = mm.find((el) =>
                    el.children.find(
                      (ch) =>
                        !ch.view &&
                        !ch.add &&
                        !ch.edit &&
                        !ch.delete &&
                        ch.permission
                    )
                  );
                  // console.log(obj);
                  if (obj) {
                    let child = obj.children.find(
                      (ch) =>
                        !ch.view &&
                        !ch.add &&
                        !ch.edit &&
                        !ch.delete &&
                        ch.permission
                    );
                    // console.log(child)
                    this.$store.commit("setPermissions", child.permission);
                    obj.children = obj.children.filter(
                      (el) => el.view || el.add || el.edit || el.delete
                    );
                    if (obj.children.length == 0) {
                      mm = mm.filter((el) => el.id != obj.id);
                    } else {
                      let ind = mm.findIndex((el) => el.id == obj.id);
                      mm.splice(ind, 1, obj);
                    }
                  } else {
                    let other = mm.find((el) =>
                      el.children.find((ch) => ch.permission)
                    );
                    // console.log(other);
                    if (other) {
                      let otherCh = other.children.find((ch) => ch.permission);
                      this.$store.commit("setPermissions", otherCh.permission);
                      // console.log(otherCh);
                    } else this.$store.commit("setPermissions", "");
                  }
                  // console.log(mm);
                  this.$store.commit("setMenu", mm);

                  // this.$store.commit(
                  //   "setMenu",
                  //   JSON.parse(res.data.data.forms)
                  // );
                  // console.log(this.$store.state.menu);
                  this.$store.commit(
                    "setCurrentMenu",
                    this.$store.state.menu[0]
                  );
                  // console.log(this.$store.state.currentMenu);

                  this.$store.state.menu.forEach((el) => {
                    // console.log(el["icon"]);
                    el.children.forEach((ch) => {
                      this.rights[ch.route] = {
                        view: ch.view,
                        add: ch.add,
                        edit: ch.edit,
                        delete: ch.delete,
                      };
                    });
                  });
                  // console.log(this.rights);
                  this.$store.commit("setRights", this.rights);
                  // console.log(this.$store.state.rights);

                  this.loading = false;

                  //this.$router.push({ name: 'dashboard-ecommerce'})

                  // this.$router.push({ path: "/" });
                  useJwt
                    .login({
                      email: "admin@demo.com",
                      password: "admin",
                    })
                    .then((response) => {
                      // console.log(response);
                      const { userData } = response.data;
                      // console.log("UserData", userData);
                      userData.fullName = this.$store.state.userData.username;
                      userData.role = this.$store.state.userData.roles;
                      useJwt.setToken(response.data.accessToken);
                      useJwt.setRefreshToken(response.data.refreshToken);
                      localStorage.setItem(
                        "userData",
                        JSON.stringify(userData)
                      );

                      this.$ability.update(userData.ability);

                      this.$store.commit(
                        "app-ecommerce/UPDATE_CART_ITEMS_COUNT",
                        0
                      );

                      // for page after login
                      // console.log(this.$store.state.currentMenu);
                      this.$router
                        .replace({
                          name: getHomeRouteForLoggedInUser(
                            this.$store.state.currentMenu.children[0].route
                          ),
                        })
                        .then(() => {
                          this.$toast({
                            component: ToastificationContent,
                            position: "top-right",
                            props: {
                              title: `Welcome Administrator`,
                              icon: "CoffeeIcon",
                              variant: "success",
                              text: `You have successfully logged in as ${this.role}. Now you can start to explore!`,
                            },
                          });
                          this.$store.commit(
                            "appConfig/UPDATE_CONTENT_WIDTH",
                            "boxed"
                          );
                        });
                    })
                    .catch((error) => {
                      this.$refs.loginForm.setErrors(error.response.data.error);
                    });
                })
                .catch((error) => {
                  // console.log(error);
                });
            } else {
              this.$bvToast.toast(
                "Please check your email or password is correct.",
                {
                  title: "Error!",
                  variant: "danger",
                  solid: true,
                }
              );
              return (this.loading = false);
            }
          })
          .catch((error) => {
            // console.log(error);
            this.$bvToast.toast(
              "Please check your email or password is correct.",
              {
                title: "Error!",
                variant: "danger",
                solid: true,
              }
            );
            this.loading = false;
          });
      } else {
        this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      }
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
        }
      });
    },
  },
  created() {
    if (window.location.hostname == "connect.hpgs.pk") {
      this.$store.commit("appConfig/UPDATE_COVERIMAGE", this.hpgsCover);
      this.$store.commit("appConfig/UPDATE_APP_LOGOIMAGE", this.hpgsSmall);
    } else if (window.location.hostname.includes("szablc.edu.pk")) {
      this.$store.commit("appConfig/UPDATE_COVERIMAGE", this.szablcCover);
      this.$store.commit("appConfig/UPDATE_APP_LOGOIMAGE", this.szablcSmall);
    }
    // console.log(this.$store.state.userData.password);
    // this.$store.commit("setDomain", "https://staging.myskool.app/api/");
    // this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
